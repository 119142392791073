import {HashLink as Link} from "react-router-hash-link";

export default function SectionFeedbacks() {
    const equipe = [
        {
            nome: 'Rodolfo Barros',
            imagem: 'https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png'
        },
        {
            nome: 'Vanessa Barros',
            imagem: 'https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png'
        },
        {
            nome: 'Felipe Abrahão',
            imagem: 'https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png'
        },
        {
            nome: 'Izabela',
            imagem: 'https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png'
        },
        {
            nome: 'João Kikuti',
            imagem: 'https://w7.pngwing.com/pngs/910/606/png-transparent-head-the-dummy-avatar-man-tie-jacket-user-thumbnail.png'
        }
    ]
    return (
        <div className='bg-blue-login flex flex-col items-center justify-center p-24 gap-16'>

            <div className='text-5xl font-extrabold text-white-1'>
                Feedbacks
            </div>

            <div className='flex gap-10'>

                {
                    equipe.map((pessoa, index) => (
                        <div className='flex flex-col w-80 items-center gap-4' key={index}>

                            <div className='w-72 h-72 rounded-full overflow-hidden'>
                                <img className='w-full h-full' src={pessoa.imagem} alt=""/>
                            </div>

                            <div
                                className='h-auto w-full font-bold text-3xl text-white-1 flex items-center justify-center'>
                                {pessoa.nome}
                            </div>

                        </div>
                    ))
                }

                {/* <div className='flex flex-col w-80 items-center gap-4'>

                    <div className='w-72 h-72 rounded-full overflow-hidden'>
                        <img className='w-full h-full' src={require("../../../assets/images/homeImages/people-01.png")}
                             alt=""/>
                    </div>

                    <div className='h-auto w-full font-bold text-3xl text-white-1 flex items-center justify-center'>
                        Rodolfo Barros
                    </div>

                    <div className='h-auto w-full font-medium text-xl text-white-1 text-center mt-auto'>
                        “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”
                    </div>

                    <div
                        className='h-auto w-full flex gap-4 font-medium text-lg text-white-1 text-left mt-auto justify-center'>
                        <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                    </div>
                </div> */}

                {/* <div className='flex flex-col w-80 items-center gap-4'>

                    <div className='w-72 h-72 rounded-full overflow-hidden'>
                        <img className='w-full h-full' src={require("../../../assets/images/homeImages/people-02.png")}
                             alt=""/>
                    </div>

                    <div className='h-auto w-full font-bold text-3xl text-white-1 flex items-center justify-center'>
                        Vanessa Barros
                    </div>

                    <div className='h-auto w-full font-medium text-xl text-white-1 text-center mt-auto'>
                        “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”
                    </div>

                    <div
                        className='h-auto w-full flex gap-4 font-medium text-lg text-white-1 text-left mt-auto justify-center'>
                        <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                    </div>
                </div>

                <div className='flex flex-col w-80 items-center gap-4'>

                    <div className='w-72 h-72 rounded-full overflow-hidden'>
                        <img className='w-full h-full' src={require("../../../assets/images/homeImages/people-03.png")}
                             alt=""/>
                    </div>

                    <div className='h-auto w-full font-bold text-3xl text-white-1 flex items-center justify-center'>
                        Felipe Abrahão
                    </div>

                    <div className='h-auto w-full font-medium text-xl text-white-1 text-center mt-auto'>
                        “Lorem Ipsum is simply dummy text of the printing and typesetting industry.”
                    </div>

                    <div
                        className='h-auto w-full flex gap-4 font-medium text-lg text-white-1 text-left mt-auto justify-center'>
                        <FaStar/><FaStar/><FaStar/><FaStar/><FaStar/>
                    </div>
                </div> */}

            </div>

            <div className='flex items-center justify-start font-bold text-xl text-white-1'>
                <Link className='bg-green-smart_city rounded-lg px-[2em] py-[0.5em]' to={'/login'}>Acessar plataforma
                    GAIA</Link>
            </div>

        </div>
    )
}