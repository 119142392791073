export default function SectionArtigos() {
    return (
        <div className='flex flex-col text-blue-login items-center justify-center p-24 bg-[#CED8E2] gap-16'>

            <div className='text-5xl font-extrabold'>
                Artigos
            </div>

            <div className='text-4xl font-extrabold'>
                Conheça nossos outros artigos
            </div>

            <div className='flex gap-10'>

                <div className='flex flex-col w-80 rounded-xl shadow-xl'>
                    <div className='h-16 bg-blue-login text-lg font-bold text-white-1 flex items-center pl-8'>Artigo 1
                    </div>
                    <div className='h-64 bg-white-1 rounded-b-xl'></div>
                </div>

                <div className='flex flex-col w-80 rounded-xl shadow-xl'>
                    <div className='h-16 bg-blue-login text-lg font-bold text-white-1 flex items-center pl-8'>Artigo 2
                    </div>
                    <div className='h-64 bg-white-1 rounded-b-xl'></div>
                </div>

                <div className='flex flex-col w-80 rounded-xl shadow-xl'>
                    <div className='h-16 bg-blue-login text-lg font-bold text-white-1 flex items-center pl-8'>Artigo 3
                    </div>
                    <div className='h-64 bg-white-1 rounded-b-xl'></div>
                </div>

                <div className='flex flex-col w-80 rounded-xl shadow-xl'>
                    <div className='h-16 bg-blue-login text-lg font-bold text-white-1 flex items-center pl-8'>Artigo 4
                    </div>
                    <div className='h-64 bg-white-1 rounded-b-xl'></div>
                </div>


            </div>

        </div>
    )
}